<template>
  <!-- 考试信息 -->
  <div class="examInfo">
    <van-icon v-if="!from" name="arrow-left" color="#fff" @click="comeBack" />
    <img v-if="bgurl != ''" :src="bgurl" alt="" class="headerImg" />
    <img v-else src="'@/assets/img/examinInfo1.png'" alt="" class="headerImg" />
    <table border="1" cellpadding="“10”" cellspacing="0" align="center">
      <caption>
        {{
          `${papername}（` + `${taketime + $t('examinInfo.examinInfoTxt1')}）`
        }}
      </caption>
      <tr>
        <th>{{ $t('examinInfo.examinInfoTxt2') }}</th>
        <th>{{ $t('examinInfo.examinInfoTxt3') }}</th>
        <th>{{ $t('examinInfo.examinInfoTxt4') }}</th>
        <th>{{ $t('examinInfo.examinInfoTxt5') }}</th>
      </tr>
      <tr v-for="(tb, tbIdx) in tableList" :key="tbIdx">
        <td align="center">{{ tb.qsflagname }}</td>
        <td align="center">{{ tb.icount }}</td>
        <td align="center">{{ tb.score }}</td>
        <td align="center">{{ tb.totalscore }}</td>
      </tr>
      <tfoot>
        <td>{{ $t('examinInfo.examinInfoTxt6') }}</td>
        <td></td>
        <td></td>
        <td>{{ totalscore }}</td>
      </tfoot>
    </table>
    <p
      class="remark_p"
      v-html="`${$t('examinInfo.examinInfoTxt7') + '<br>' + remark}`"
    ></p>
    <div class="button_div">
      <van-button type="info" @click="examination">{{
        $t('examinInfo.examinInfoTxt8')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { getCourseTestTotal } from '@api/wxpx.js'
export default {
  data() {
    let from = this.$route.query.from || ''
    let guid = this.$route.query.guid
    return {
      guid,
      empid: this.$route.query.empid,
      username: this.$route.query.username,
      from,
      paperid: this.$route.query.paperid,
      mautoid: this.$route.query.mautoid,
      teststyle: this.$route.query.teststyle, // 考试类别: 模拟(moni)、正式(zhengshi)、补考(bukao)、投递(toudi)、面试(mianshi)
      tableList: [],
      bgurl: '',
      papername: '',
      taketime: '',
      totalscore: '',
      remark: ''
    }
  },
  methods: {
    comeBack() {
      if (!this.from) {
        this.$router.push('/toExamined')
      }
    },
    examination() {
      // this.$router.push(`/examination/${this.paperid}`)
      this.$router.push({
        path: '/examination',
        query: {
          paperid: this.paperid,
          mautoid: this.mautoid,
          teststyle: this.teststyle,
          from: this.from,
          empid: this.$route.query.empid,
          username: this.$route.query.username,
          guid: this.$route.query.guid
        }
      })
    },
    GetCourseTestTotal() {
      getCourseTestTotal({ paperid: this.paperid }).then(res => {
        if (res.iserror == 0) {
          this.bgurl = res.baseinfo[0].bgurl
          this.papername = res.baseinfo[0].papername
          this.taketime = res.baseinfo[0].taketime
          this.totalscore = res.baseinfo[0].totalscore
          this.remark = res.baseinfo[0].remark
          this.remark = this.remark
            ? this.remark.replace(/\\n/g, '<br>')
            : this.remark
          this.tableList = res.data
        }
      })
    }
  },
  created() {
    this.GetCourseTestTotal()
  }
}
</script>

<style lang="less" scoped>
.examInfo {
  position: relative;
  text-align: center;
  height: 100vh;
  padding-bottom: 30px;
  .van-icon {
    position: absolute;
    top: 50px;
    left: 30px;
    font-size: 38px;
  }
  .van-icon-arrow-left::before {
    content: '\F008';
    text-shadow: -8px 4px 5px #333;
  }
  .headerImg {
    width: 100%;
    height: 472px;
  }
  table {
    table-layout: fixed !important;
    width: 90% !important;
    border-collapse: collapse;
    border: none;
    font-size: 28px;
    margin: auto;
    margin-bottom: 34px;
  }

  td,
  th {
    width: 1px;
    white-space: nowrap; /* 自适应宽度*/
    word-break: keep-all; /* 避免长单词截断，保持全部 */
    border: solid #676767 1px;
    text-align: center;
    white-space: pre-line;
    word-break: break-all !important;
    word-wrap: break-word !important;
    display: table-cell;
    vertical-align: middle !important;
    white-space: normal !important;
    height: auto;
    vertical-align: text-top;
    padding: 2px 2px 0 2px;
    display: table-cell;
  }
  caption {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  .remark_p {
    padding: 0 40px 120px 40px;
    text-align: justify;
    font-size: 32px;
    // padding-bottom: 60px;
  }
  .button_div {
    width: 100%;
    height: 60px;
    background: #f6f6f6;
    position: fixed;
    bottom: 0;
    .van-button {
      width: 90%;
      position: fixed;
      bottom: 10px;
      left: 5%;
    }
  }
}
</style>
